import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomePage from "../views/HomePage.vue";

const titleStart = "AYGA ::";
const DEFAULT_TITLE = titleStart;

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    meta: { title: titleStart + " AcroYoga Gathering" },
  },
  {
    path: "/branding",
    name: "Branding",
    meta: { title: titleStart + " branding" },
    component: () =>
      import(/* webpackChunkName: "branding" */ "../views/Branding.vue"),
  },
  {
    path: "/gatherings",
    name: "Gatherings",
    meta: { title: titleStart + " Gatherings List" },
    component: () =>
      import(/* webpackChunkName: "gathering" */ "../views/Gatherings.vue"),
  },
  {
    path: "/whatisayga",
    name: "WahtIsAyga",
    meta: { title: titleStart + " What is Ayga" },
    component: () =>
      import(/* webpackChunkName: "what" */ "../views/WhatIsAyga.vue"),
  },
  {
    path: "/portugal-pre-laura-caspian",
    name: "PortugalPreLaura",
    meta: { title: titleStart + " Portugal Pre-gathering 2024" },
    component: () =>
      import(/* webpackChunkName: "pre" */ "../views/PortugalPreLaura.vue"),
  },
  {
    path: "/portugal-2024",
    name: "Portugal",
    meta: { title: titleStart + " Portugal 2024" },
    component: () =>
      import(/* webpackChunkName: "portugal" */ "../views/Portugal.vue"),
  },
  {
    path: "/india",
    name: "India",
    meta: { title: titleStart + " India 2024" },
    component: () =>
      import(/* webpackChunkName: "india" */ "../views/India.vue"),
  },
  {
    path: "/ahmedabad-2024",
    name: "Ahmedabad",
    meta: { title: titleStart + " Ahmedabad (India) 2024" },
    component: () =>
      import(/* webpackChunkName: "ahmedabad" */ "../views/Ahmedabad.vue"),
  },
  {
    path: "/delhi-2024",
    name: "Delhi",
    meta: { title: titleStart + " Delhi (India) 2024" },
    component: () =>
      import(/* webpackChunkName: "delhi" */ "../views/Delhi.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to: any) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  document.title = to.meta.title || DEFAULT_TITLE;
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;
