
import { defineComponent } from "vue";
import MyNavigation from "@/components/Navigation.vue";
import MyFooter from "@/components/Footer.vue";

export default defineComponent({
  name: "App",
  components: {
    MyNavigation,
    MyFooter,
  },
});
