
import { defineComponent } from "vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
export default defineComponent({
  name: "MyCountdown",
  components: {
    VueCountdown,
  },
  props: {
    date: Number,
  },
});
