
import { defineComponent } from "vue";
export default defineComponent({
  name: "MyParallax",
  props: {
    image: { type: String, default: "yoga.jpg" },
  },
  data() {
    return {
      imgObj: {
        backgroundImage: `url(${require("@/assets/hd-images/" + this.image)})`,
      },
    };
  },
});
