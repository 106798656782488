import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/scss/globals.scss";
import { VueCookieNext } from "vue-cookie-next";
import VueGtag from "vue-gtag";

const app = createApp(App);
app.config.globalProperties.globalVar = { language: "pt_PT" };

VueCookieNext.config({ expire: "30d" });

app
  .use(router)
  .use(VueCookieNext)
  .use(VueGtag, {
    config: {
      id: "G-GNYBTXRSPP",
      params: {
        send_page_view: true,
      },
    },
  })
  .mount("#app");
