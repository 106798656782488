
import { defineComponent } from "vue";
export default defineComponent({
  name: "MyTestimonial",
  props: {
    name: { type: String, default: "Name" },
    type: {
      type: String,
      default: "Participant",
    },
    description: { type: String, default: "Description" },
  },
});
